/* tslint:disable */
/* eslint-disable */
export interface BookingsGetBookingAvailabilitiesQuery {
  bookingTypeId?: string;
  fromDate?: null | string;
  legalEntityId?: string;
  officeId?: string;
  primaryLocationId?: string;
  subOfficeId?: string;
  toDate?: null | string;
  allBookings?: boolean;
  isRecurring?: null | boolean;
  numberOfDays?: null | number;
  includeWeekends?: null | boolean;
}
