/* tslint:disable */
/* eslint-disable */
import { ProcessesProcessInstanceSteps } from './processes-process-instance-steps';
import { ProcessesBtVerdeProcessStatus } from './processes-bt-verde-process-status';
export interface ProcessesProcessInstance {
  cancellationReason?: null | string;
  cancelledDate?: null | string;
  completedDate?: null | string;
  duration?: null | number;
  instanceSteps?: null | Array<ProcessesProcessInstanceSteps>;
  processStatus?: ProcessesBtVerdeProcessStatus;
  processInstanceId?: string;
  startDate?: null | string;
  statusMessage?: null | string;
}
