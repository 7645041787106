import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments-verde/environment';
import {
  UserApiConfigService,
  UserAppConfigDto,
  UserLegalEntityConfigDto,
  UserSecurityFunctionDto,
  UserUserDto,
  UserVerdeErrorType,
  UserApiUserService,
} from '@verde/api';
import { AuthenticationService, StorageService, ViewSDKClient } from '@verde/core';
import { uniqBy } from 'lodash';
import { NgxPermissionsService } from 'ngx-permissions';
import { BehaviorSubject, Observable, Subject, combineLatest, debounceTime, distinctUntilChanged, take, takeUntil } from 'rxjs';
import { AuthState } from '../models/auth-state';

export interface UserSecurityLegalEntity {
  legalEntityId?: string;
  legalEntityName?: null | string;
}

export interface GroupedMenu {
  groupName: string;
  groupingSequence: number;
  items: UserSecurityFunctionDto[];
  expanded?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private onDestroy$ = new Subject<boolean>();

  config$: BehaviorSubject<UserAppConfigDto | undefined> = new BehaviorSubject<UserAppConfigDto | undefined>(undefined);
  disableAnimation$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  proxyUser$: BehaviorSubject<UserUserDto | undefined> = new BehaviorSubject<UserUserDto | undefined>(undefined);
  configLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  permissionsLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userLoaded$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private authenticationService: AuthenticationService,
    private userApiConfigService: UserApiConfigService,
    private storageService: StorageService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private viewSDKClient: ViewSDKClient,
    private userApiUserService: UserApiUserService,
  ) {
    combineLatest([this.authenticationService.authState$, this.authenticationService.loaded$])
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(async (data) => {
        const authState = data[0];
        const authLoaded = data[1];

       
        if (authState && authLoaded) {
          try {
            const requestBody = {
              userEmail: authState.userEmail ?? '',
              minimal: true,
            };

            console.log(requestBody);
            console.log(authState.tenantId);
            console.log(authState);
            this.userApiUserService
              .getUserByEmail({
                body: requestBody,
              })
              .pipe(take(1))
              .subscribe(
                async (user) => {
                  if (user) {
                    this.userLoaded$.next(true);
                    this.proxyUser$.next({ ...user });
                    const config = this.config$.getValue() ?? {};
                    this.config$.next({
                      ...config,
                      user,
                    });

                    if (user?.azureObjectId !== undefined && user?.tenantId !== undefined) {
                      await this.getUserAppConfig(authState);
                    }
                  } else {
                    this.catchErr('User not found.');
                  }
                },
                (error) => {
                  this.catchErr();
                },
              );
          } catch (err) {
            this.catchErr();
          }
        }
      });
  }

  catchErr(err = 'User not found due to connection timeout.') {
    this.userLoaded$.next(false);
    this.proxyUser$.next(undefined);
    const config = this.config$.getValue() ?? {};
    const verdeEnvironmentConfig = config?.verdeEnvironmentConfig ?? {};
    this.config$.next({
      ...config,
      verdeEnvironmentConfig: {
        ...verdeEnvironmentConfig,
        verdeError: true,
        verdeErrorDetail: 'User Configuration Load Failed',
        verdeErrorMessage: `${err} Please try again later.`,
        verdeErrorType: UserVerdeErrorType.ConfigNotFound,
      },
    });
  }

  async getUserAppConfig(authState: AuthState | undefined) {
    this.configLoaded$.next(false);
    if (authState !== undefined && (authState.isMicrosoftAuthed || authState.isTeamsAuthed)) {
      try {
        const config = await this.userApiConfigService
          .getUserAppConfig({
            body: {
              userEmail: authState.userEmail ?? '',
            },
          })
          .toPromise();

        if (config) {
          this.config$.next(config);
          if (!config?.verdeEnvironmentConfig?.verdeError) {
            this.storageService.sessionSaveKey('subKey', config?.verdeEnvironmentConfig?.subscriptionKey ?? '');
            this.storageService.sessionSaveKey('APIURL', config?.verdeEnvironmentConfig?.apiManagement ?? '');
            this.proxyUser$.next(config?.user);
            this.loadPermissions(config?.security?.functions);
            (window as any).tenantId = authState.tenantId;
            if (environment.envConfig.includes('local')) {
              this.viewSDKClient.credentialKey = 'f54a9017a2714dcfa2f453c7fb952d07';
            } else {
              this.viewSDKClient.credentialKey = config?.verdeEnvironmentConfig?.adobeEmbedKey ?? '';
            }
          }
          this.configLoaded$.next(true);
        } else {
          this.clear(undefined);
        }
      } catch (err) {
        this.clear({
          verdeEnvironmentConfig: {
            verdeError: true,
            verdeErrorType: UserVerdeErrorType.ConfigNotFound,
            verdeErrorMessage: 'Config Load Failed',
            verdeErrorDetail: 'Verde Platform Config not found due to connection timeout. Please try again later.',
          },
        });
        this.configLoaded$.next(true);
      }
    } else {
      this.clear(undefined);
    }
  }

  clear(config?: UserAppConfigDto) {
    this.config$.next(config);
    this.configLoaded$.next(false);
    this.storageService.sessionDeleteKey('subKey');
    this.storageService.sessionDeleteKey('APIURL');
    this.proxyUser$.next(undefined);
    this.clearPermissions();
    this.router.navigate(['/login']);
  }

  // Proxy Functionality

  changeProxyUser(user: UserUserDto) {
    this.proxyUser$.next(user);
  }

  applyNewProxyUser(user: UserUserDto) {
    if (user.employeeId === this.user?.employeeId) {
      this.proxyUser$.next(this.user);
    } else {
      this.team?.forEach((t: UserUserDto) => {
        if (t.employeeId === user.employeeId) {
          this.proxyUser$.next(t);
        }
      });
    }
  }

  // Getters

  get verdeEnvironmentConfig() {
    return this.config$.getValue()?.verdeEnvironmentConfig;
  }

  get legalEntityConfig() {
    return this.config$.getValue()?.legalEntityConfig;
  }

  get proxyUser() {
    return this.proxyUser$.getValue();
  }

  get user() {
    return this.config$.getValue()?.user;
  }

  get managerTeam() {
    return this.config$.getValue()?.managerTeam ?? [];
  }

  get team() {
    return this.config$.getValue()?.team ?? [];
  }

  // Permissions

  get functions() {
    return this.config$.getValue()?.security?.functions ?? [];
  }

  loadPermissions(functions: null | undefined | Array<UserSecurityFunctionDto>) {
    this.permissionsLoaded$.next(false);

    if (functions && Array.isArray(functions)) {
      const permissions = functions.map((f) => f.technicalName?.toString() ?? '').filter((f) => f !== '');
      this.permissionsService.loadPermissions([...new Set([...permissions])]);
      this.permissionsLoaded$.next(true);
    }
  }

  clearPermissions() {
    this.permissionsLoaded$.next(false);
    this.permissionsService.flushPermissions();
  }

  // Permissions Filter
  //public filterUserPermissions(module: string, grouping: string, isDropdown: boolean): Array<UserSecurityFunctionDto> {
  //  let functionsFiltered: Array<UserSecurityFunctionDto> = new Array<UserSecurityFunctionDto>();

  //  if (this.user) {
  //    if (isDropdown) {
  //      this.functions?.forEach((t: UserSecurityFunctionDto) => {
  //        if (t.module === module && t.dropdownName === grouping && t.legalEntityId === this.user?.legalEntityId) {
  //          functionsFiltered.push(t);
  //        }
  //      });
  //    } else {
  //      this.functions?.forEach((t: UserSecurityFunctionDto) => {
  //        if (t.module === module && t.menuContext === grouping && t.legalEntityId === this.user?.legalEntityId) {
  //          functionsFiltered.push(t);
  //        }
  //      });
  //    }
  //  }
  //  return functionsFiltered;
  //}

  // Permissions Filter
  public filterUserPermissions(grouping: string, isDropdown: boolean, includeAllEntities: boolean = false): Array<UserSecurityFunctionDto> {
    let functionsFiltered: Array<UserSecurityFunctionDto> = new Array<UserSecurityFunctionDto>();
    let uniqueKeys = new Set<string>();

    if (this.user) {
      if (isDropdown) {
        this.functions?.forEach((t: UserSecurityFunctionDto) => {
          if (t.dropdownName === grouping && (includeAllEntities ? true : t.legalEntityId === this.user?.legalEntityId)) {
            const uniqueKey = `${t.dropdownName}-${t.legalEntityId}-${t.functionId}`;
            if (!uniqueKeys.has(uniqueKey)) {
              uniqueKeys.add(uniqueKey);
              functionsFiltered.push(t);
            }
          }
        });
      } else {
        this.functions?.forEach((t: UserSecurityFunctionDto) => {
          if (t.menuContext === grouping && (includeAllEntities ? true : t.legalEntityId === this.user?.legalEntityId)) {
            const uniqueKey = `${t.menuContext}-${t.legalEntityId}-${t.functionId}`;
            if (!uniqueKeys.has(uniqueKey)) {
              uniqueKeys.add(uniqueKey);
              functionsFiltered.push(t);
            }
          }
        });
      }
    }

    return functionsFiltered;
  }

  public filterDynamicGroup(dynamicGroup: string, legalEntityId: string): Array<UserSecurityFunctionDto> {
    let functionsFiltered: Array<UserSecurityFunctionDto> = new Array<UserSecurityFunctionDto>();
    if (this.user) {
      this.functions?.forEach((t: UserSecurityFunctionDto) => {
        if (t.dynamicGroupName === dynamicGroup && t.legalEntityId === legalEntityId) {
          functionsFiltered.push(t);
        }
      });
    }
    return functionsFiltered;
  }

  public sortGroupMenuItems(functionList: Array<UserSecurityFunctionDto>): GroupedMenu[] {
    const groupedMenu: GroupedMenu[] = Array.from(
      functionList
        .reduce((map, item) => {
          if (!map.has(item.groupName)) {
            map.set(item.groupName, { groupName: item.groupName, groupingSequence: item.groupingSequence, items: [] });
          }
          map.get(item.groupName).items.push(item);
          return map;
        }, new Map<string, GroupedMenu>())
        .values(),
    ).sort((a, b) => a.groupingSequence - b.groupingSequence);

    groupedMenu.forEach((group) => {
      group.items.sort((a, b) => a.menuSequence - b.menuSequence);
      group.expanded = group.items.some((item) => item.autoExpand);
    });

    console.log('Groups', groupedMenu);
    return groupedMenu;
  }

  public getModuleEntityConfigs(technicalName: string): Observable<UserLegalEntityConfigDto[]> {
    const entities = this.getLegalEntitiesWithPermission(technicalName);
    const entityIds = entities.map((entity) => entity.legalEntityId ?? '');

    return this.userApiConfigService.getUserPeopleManagementConfig({
      body: {
        legalEntity: entityIds,
      },
    });
  }

  public get isPermissionsLoaded() {
    return this.permissionsLoaded$.getValue();
  }

  public userHasPermission(technicalName: string) {
    const func = this.getPermission(technicalName);
    return func !== undefined && func !== null;
  }

  public getPermission(technicalName: string) {
    return this.functions.find((func) => func.technicalName === technicalName && func.legalEntityId === this.user?.legalEntityId);
  }

  public getPermissionApprovalSteps() {
    return this.functions.filter((func) => func.approvalStep);
  }

  public getPermissionModules() {
    const entries = this.functions.filter((func) => func.module);
    return uniqBy(entries, 'module');
  }

  public getLegalEntities(): UserSecurityLegalEntity[] {
    const entries = this.functions.map((f) => {
      return { legalEntityName: f.legalEntityName, legalEntityId: f.legalEntityId };
    });

    return uniqBy(entries, 'legalEntityId');
  }

  public getLegalEntityIds(): string[] {
    return this.getLegalEntities()
      .map((e) => e.legalEntityId ?? '')
      .filter(Boolean);
  }

  public getLegalEntitiesWithPermission(technicalName: string): UserSecurityLegalEntity[] {
    const entries = this.functions
      .filter((func) => func.technicalName === technicalName)
      .map((f) => {
        return { legalEntityName: f.legalEntityName, legalEntityId: f.legalEntityId };
      });

    return uniqBy(entries, 'legalEntityId');
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
