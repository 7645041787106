import { Injectable } from '@angular/core';
import { GridDataResult } from '@progress/kendo-angular-grid';
import { GroupDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import {
  BookingsApiBookingsService,
  BookingsBookingsFilteredDto,
  BookingsGetBookingAvailabilitiesFilteredDto,
  BookingsGetBookingAvailabilitiesQuery,
  BookingsGetBookingsByUserIdQuery,
  BookingsPatchBookingCommand,
  BookingsPostBookingsCommand,
} from '@verde/api';
import moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable, ReplaySubject, Subject, take, takeUntil } from 'rxjs';
import { VerdeApprovalService } from '../../../layout/verde-approval-panel/services/verde-approval.service';
import { ModalService } from '../../modals/modal.service';
import { CommonHelpers, StateCode, UserService } from '@verde/core';

@Injectable({
  providedIn: 'root',
})
export class BookingsDataService {
  refreshBooking: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  allMyBookings: ReplaySubject<BookingsBookingsFilteredDto[]> = new ReplaySubject<BookingsBookingsFilteredDto[]>(1);
  onDestroy$: Subject<any> = new Subject();

  bookingStartDate: string;
  bookingEndDate: string;
  bookingDate: string;
  bookingAvailabilitySearchDetails: any;
  BookingItemName: string;
  bookingItemSelected: BookingsGetBookingAvailabilitiesFilteredDto;

  bookingAvailabilities: GridDataResult;
  bookingAvailabilityGridData: any[] = [];

  allBookingsForTheDay: GridDataResult;
  allBookingsForTheDayGridData: BookingsGetBookingAvailabilitiesFilteredDto[] = [];

  allBookings: GridDataResult;
  allBookingsGridData: BookingsGetBookingAvailabilitiesFilteredDto[] = [];

  bookingId: string;
  employeeId: string;
  showConfirmationModal: boolean;
  bookingAvailabilitiesLoading: boolean;
  allBookingsForTheDayLoading: boolean;
  allBookingsLoading: boolean;

  DisplayMessage: string;
  disableAnimation: boolean;
  allMyBookingsLoading = false;

  deskBookedImageStyle = 'filter: invert(50%) sepia(100%) saturate(5000%) hue-rotate(330deg) brightness(90%);';
  deskUnavailableImageStyle = 'filter: grayscale(100%) brightness(90%);';
  deskAvailableCursorStyle = 'cursor: pointer;';
  deskUnavailableCursorStyle = 'cursor: default;';

  bookingsGridView: any[];
  allBookingsForTheDayGridView: any[];
  allBookingsGridView: any[];

  gridSort: SortDescriptor[] = [
    {
      field: 'bookingItem',
      dir: 'asc',
    },
  ];

  gridGroups: GroupDescriptor[] = [{ field: 'bookingItem' }];

  bookingGridGroups: GroupDescriptor[] = [{ field: 'bookingType' }, { field: 'bookingItem' }];

  constructor(
    private bookingService: BookingsApiBookingsService,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private sidebarService: VerdeApprovalService,
    private modalService: ModalService,
  ) {}

  loadMyBookings(employeeId: string): void {
    this.employeeId = employeeId;
    this.allMyBookingsLoading = true;
    this.bookingService
      .bookingsByUserId({
        body: {
          userId: employeeId,
          legalEntityId: this.userService?.user?.legalEntityId,
        },
      })
      .pipe(take(1))
      .subscribe({
        next: (o) => this.allMyBookings.next(o),
        error: () => (this.allMyBookingsLoading = false),
        complete: () => (this.allMyBookingsLoading = false),
      });
  }

  loadAllBookings() {
    this.allBookingsLoading = true;
    this.bookingService
      .getBookingAvailabilities({
        body: {
          legalEntityId: this.userService?.user?.legalEntityId,
          fromDate: moment().format('YYYY-MM-DDT00:00:00[Z]'),
          allBookings: true,
        } as BookingsGetBookingAvailabilitiesQuery,
      })
      .pipe(take(1))
      .subscribe({
        next: (allBookings) => {
          this.allBookingsGridData = [...allBookings];
          this.allBookingsGridView = this.allBookingsGridData;

          this.allBookings = {
            data: [...this.allBookingsGridData],
            total: this.allBookingsGridData.length,
          };
        },
        error: () => (this.allBookingsLoading = false),
        complete: () => (this.allBookingsLoading = false),
      });
  }

  openConfirmModel(bookingItem: BookingsGetBookingAvailabilitiesFilteredDto) {
    this.bookingItemSelected = bookingItem;
    this.bookingStartDate = moment(this.bookingAvailabilitySearchDetails?.bookingFrom).format('HH:mm');
    this.bookingEndDate = moment(this.bookingAvailabilitySearchDetails?.bookingTo).format('HH:mm');
    this.bookingDate = CommonHelpers.formatDate(this.bookingAvailabilitySearchDetails?.bookingFrom, 'MMM D');
    this.bookingAvailabilitySearchDetails.bookingItem = this.bookingItemSelected.bookingItemsId;
    this.bookingAvailabilitySearchDetails.BookingItemName = this.bookingItemSelected.bookingItem;
    this.modalService.open(`bookingDetailsModal`);
  }

  bookItem(legalEntity: string = this.userService?.user?.legalEntityId, employee: string = this.userService?.user?.employeeId) {
    this.spinner.show('bookingdetailsloading');

    const isProxyBooking = this.userService.proxyUser != null && this.userService.proxyUser?.employeeId != this.userService.user?.employeeId ? true : false;

    if (isProxyBooking) {
      employee = this.userService.proxyUser?.employeeId;
    }

    const booking: BookingsBookingsFilteredDto = {
      bt_fromdate: moment(this.bookingAvailabilitySearchDetails?.bookingFrom).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      bt_todate: moment(this.bookingAvailabilitySearchDetails?.bookingTo).format('YYYY-MM-DDTHH:mm:ss[Z]'),
      odataPostLegalEntity: '/bthr_legalentities(' + legalEntity + ')',
      odataPostDuration: '/bt_verdebookingduration(' + this.bookingAvailabilitySearchDetails?.bookingDuration + ')',
      odataPostPrimaryLocation: '/bthr_location(' + this.bookingAvailabilitySearchDetails?.primaryLocation + ')',
      odataPostBookingType: '/bthr_location(' + this.bookingAvailabilitySearchDetails?.bookingType + ')',
      odataPostBookingItem: '/bt_verdebookingitems(' + this.bookingAvailabilitySearchDetails?.bookingItem + ')',
      odataPostEmployee: '/bthr_employee(' + employee + ')',
      odataPostOffice: this.bookingAvailabilitySearchDetails?.office != null ? '/bt_verdeoffice(' + this.bookingAvailabilitySearchDetails?.office + ')' : null,
      odataPostSubOffice:
        this.bookingAvailabilitySearchDetails?.subOffice != null ? '/bt_verdesuboffice(' + this.bookingAvailabilitySearchDetails?.subOffice + ')' : null,
      odataPostDepartment: '/bthr_department(' + this.bookingAvailabilitySearchDetails?.DepartmentToPass + ')',
      bt_bookingreason: this.bookingAvailabilitySearchDetails?.BookingReason != null ? this.bookingAvailabilitySearchDetails?.BookingReason : null,
      bt_Departmental: false,
      stateCode: StateCode.Active,
      bt_ProxyEmployee: isProxyBooking ? '/bthr_employee(' + this.userService?.user?.employeeId + ')' : null, //Set the current logged in user as the proxy
      isProxy: isProxyBooking,
      isRecurring: this.bookingAvailabilitySearchDetails?.isRecurring,
      numberOfDays: this.bookingAvailabilitySearchDetails?.numberOfDays,
      includeWeekends: this.bookingAvailabilitySearchDetails?.includeWeekends,
    };

    const newBooking: BookingsPostBookingsCommand = booking;

    this.bookingService
      .postBookings({ body: newBooking })
      .pipe(take(1), takeUntil(this.onDestroy$))
      .subscribe(
        (success) => {
          console.log(success);
          this.updateBookings(true);
          this.loadMyBookings(employee);
          this.loadAllBookings();
          this.sidebarService.setShowSidebar(false);
          this.spinner.hide('bookingdetailsloading');
        },
        (error) => {
          console.error(error);
        },
      );
  }

  bookDesk(deskId: string) {
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bookingItem === `${deskId}`);
    if (bookingItem && !bookingItem?.isBooked) {
      this.openConfirmModel(bookingItem);
    }
  }
  getDeskImageStyle(deskId: string) {
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bookingItem === `${deskId}`);
    if (bookingItem) {
      return bookingItem?.isBooked ? this.deskBookedImageStyle : '';
    } else {
      return this.deskUnavailableImageStyle;
    }
  }
  getDeskCursorStyle(deskId: string) {
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bookingItem === `${deskId}`);
    if (bookingItem) {
      return bookingItem?.isBooked ? this.deskUnavailableCursorStyle : this.deskAvailableCursorStyle;
    } else {
      return this.deskUnavailableCursorStyle;
    }
  }
  getDeskToolTip(deskId: string) {
    const bookingItem = this.bookingsGridView.find((desk) => desk?.bookingItem === `${deskId}`);
    if (bookingItem) {
      return bookingItem?.isBooked
        ? `${bookingItem?.bookingItem} Booked by ${bookingItem?.fullName} from ${moment(bookingItem?.fromDate).format('HH:mm')} to ${moment(
            bookingItem?.toDate,
          ).format('HH:mm')} on ${CommonHelpers.formatDate(this.bookingAvailabilitySearchDetails?.bookingFrom, 'MMM D')}`
        : `Book ${bookingItem?.bookingItem} from ${moment(this.bookingAvailabilitySearchDetails?.bookingFrom).format('HH:mm')} to ${moment(
            this.bookingAvailabilitySearchDetails?.bookingTo,
          ).format('HH:mm')} on ${CommonHelpers.formatDate(this.bookingAvailabilitySearchDetails?.bookingFrom, 'MMM D')}`;
    } else {
      return `${bookingItem?.bookingItem ?? deskId} Unavailable`;
    }
  }

  postBooking(booking: BookingsPostBookingsCommand): Observable<boolean> {
    return this.bookingService.postBookings({ body: booking });
  }

  updateBooking(booking: BookingsPatchBookingCommand): Observable<boolean> {
    return this.bookingService.updateBooking({ body: booking });
  }

  getBookingSummary(userRequest: BookingsGetBookingsByUserIdQuery): Observable<BookingsBookingsFilteredDto[]> {
    return this.bookingService.bookingsSummary({ body: userRequest });
  }

  getBookingByUserId(userRequest: BookingsGetBookingsByUserIdQuery): Observable<BookingsBookingsFilteredDto[]> {
    return this.bookingService.bookingsByUserId({ body: userRequest });
  }

  updateBookings(bookings: boolean) {
    this.refreshBooking.next(bookings);
  }
}
