import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ProcessesApiProcessesService } from '@verde/api';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'process-timeline',
  templateUrl: './process-timeline.component.html',
  styleUrls: ['./process-timeline.component.scss'],
})
export class ProcessTimelineComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  disableAnimation: boolean;
  events: any[] = [];
  pageIsLoading = true;

  @Input() processInstanceId: string;

  constructor(private processApiService: ProcessesApiProcessesService) {}

  ngOnInit(): void {
    this.processInstanceId = '7f44a81d-1c65-ef11-bfe2-000d3ab0ad99';
    this.processApiService
      .getProcessInstanceAsync({
        body: { processInstanceId: this.processInstanceId }, //recordId: '7f44a81d-1c65-ef11-bfe2-000d3ab0ad99'
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((processInstance) => {
        if (processInstance?.instanceSteps) {
          const formatDuration = (minutes: number): string => {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours}h ${remainingMinutes}m`;
          };

          this.events = processInstance.instanceSteps.map((step) => ({
            description: `${step.processStepStatusMessage}`,
            date: new Date(step.stepEndDate),
            title: `${step.processInstanceStepName} - ${step.processStepStatus}`,
            subtitle: step.stepEndDate
              ? `${moment(step.stepStartDate).format('YYYY-MM-DD HH:mm:ss')} - ${moment(step.stepEndDate).format(
                  'YYYY-MM-DD HH:mm:ss',
                )} Duration: ${formatDuration(step.stepDuration)}`
              : `Start Date: ${moment(step.stepStartDate).format('YYYY-MM-DD HH:mm:ss')}`,
          }));
        }
        this.pageIsLoading = false;
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
