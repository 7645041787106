import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FieldType, FieldTypeConfig, FormlyFieldProps } from '@ngx-formly/core';
import { ProcessesApiProcessesService } from '@verde/api';
import moment from 'moment';
import { Subject, takeUntil } from 'rxjs';

export interface KendoFormlyTimelineProps extends FormlyFieldProps {
  processInstanceId?: string;
  loading?: boolean;
}

@Component({
  selector: 'verde-kendo-formly-timeline',
  templateUrl: './timeline.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class KendoFormlyTimeline extends FieldType<FieldTypeConfig<KendoFormlyTimelineProps>> implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  events: any[] = [];
  pageIsLoading = true;

  constructor(private processApiService: ProcessesApiProcessesService, private cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.processApiService
      .getProcessInstanceAsync({
        body: { processInstanceId: this.props.processInstanceId },
      })
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((processInstance) => {
        if (processInstance?.instanceSteps) {
          const formatDuration = (minutes: number): string => {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours}h ${remainingMinutes}m`;
          };

          this.events = processInstance.instanceSteps
            .filter((step) => step.stepStartDate !== undefined)
            .map((step) => ({
              description: `${step.processStepStatusMessage}`,
              date: new Date(step.stepStartDate),
              title: `${step.processInstanceStepName} - ${step.processStepStatus}`,
              subtitle: step.stepEndDate
                ? `${moment(step.stepStartDate).format('YYYY-MM-DD HH:mm:ss')} - ${moment(step.stepEndDate).format(
                    'YYYY-MM-DD HH:mm:ss',
                  )} Duration: ${formatDuration(step.stepDuration)}`
                : `Start Date: ${moment(step.stepStartDate).format('YYYY-MM-DD HH:mm:ss')}`,
            }));
        }

        this.pageIsLoading = false;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
