/* tslint:disable */
/* eslint-disable */
import { ProcessesBtProcessStatus } from './processes-bt-process-status';
export interface ProcessesProcessInstanceSteps {
  previousStepCompleted?: null | boolean;
  processStepStatus?: ProcessesBtProcessStatus;
  processStepStatusMessage?: null | string;
  stepCancellationReason?: null | string;
  stepCancelledDate?: null | string;
  stepCompleted?: null | boolean;
  stepDuration?: null | number;
  stepEndDate?: null | string;
  stepStartDate?: null | string;
  processInstanceStepName?: null | string;
}
