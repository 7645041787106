<verde-main-header *ngIf="contextIsBrowser()" (logout)="microsoftLogout()"></verde-main-header>

<verde-approval-panel [direction]="'right'" [navWidth]="sidebarSize" [duration]="0.5"> </verde-approval-panel>

<verde-online-training-modal *ngIf="showOnlineTrainingModal" [onlineTrainingConfig]="onlineTrainingConfig[0]" (hideModalEvent)="hideOnlinetrainingModal()">
</verde-online-training-modal>

<div class="custom-toolbar">
  <img
    src="https://verdeimages.z1.web.core.windows.net/Images/Verde/Menu/default/home.svg"
    (click)="navigateSomewhere('bt_home', true)"
    width="24"
    height="24"
    class="home_icon"
    kendoTooltip
    position="bottom"
    [callout]="false"
    title="Home"
  />
  <div>
    <span class="toolbar-content-left">{{ navigationService?.selected }}</span>
    <span class="toolbar-content-right">
      <button
        *ngIf="userService.verdeEnvironmentConfig?.allowModelDrivenEmbedding && contextIsBrowser() && fleetActive()"
        kendoButton
        themeColor="primary"
        style="margin-right: 8px"
        (click)="navDataverseEmbedding()"
      >
        Fleet Admin
      </button>
      <img
        *ngxPermissionsOnly="['FLEET-FLEET-MANAGER-WORKSPACE']"
        src="https://verdeimages.z1.web.core.windows.net/Images/Verde/MyWorkspace/fleetmanager.svg"
        (click)="navigateSomewhere('bt_fleetmanagerworkspace', true)"
        width="24"
        height="24"
        kendoTooltip
        position="bottom"
        [callout]="false"
        title="Fleet Manager"
        class="refresh_icon"
        style="margin-right: 8px"
      />
      <img
        *ngxPermissionsOnly="['ADMIN-PROCESS-WORKSPACE']"
        src="https://verdeimages.z1.web.core.windows.net/Images/Verde/MyWorkspace/process.svg"
        (click)="navigateSomewhere('bt_processesworkspace', true)"
        width="24"
        height="24"
        kendoTooltip
        position="bottom"
        [callout]="false"
        title="Processes"
        class="refresh_icon"
        style="margin-right: 8px"
      />
      <img
        *ngxPermissionsOnly="['PEOPLE-MY-TEAM-WORKSPACE']"
        src="https://verdeimages.z1.web.core.windows.net/Images/Verde/MyWorkspace/myteam.svg"
        (click)="navigateSomewhere('bt_myteamworkspace', true)"
        width="24"
        height="24"
        kendoTooltip
        position="bottom"
        [callout]="false"
        title="My Team"
        class="refresh_icon"
        style="margin-right: 8px"
      />
      <img
        *ngxPermissionsOnly="['PEOPLE-PEOPLE-MANAGEMENT-ACCESS']"
        src="https://verdeimages.z1.web.core.windows.net/Images/Verde/MyWorkspace/peoplemanage.svg"
        (click)="navigateSomewhere('bt_hrportal', true)"
        width="24"
        height="24"
        kendoTooltip
        position="bottom"
        [callout]="false"
        title="People Management"
        class="refresh_icon"
        style="margin-right: 8px"
      />
      <img
        *ngIf="userService.legalEntityConfig?.myProfile"
        src="https://verdeimages.z1.web.core.windows.net/Images/Verde/MyWorkspace/myprofile.svg"
        (click)="navigateSomewhere('bt_myprofile', true)"
        width="24"
        height="24"
        kendoTooltip
        position="bottom"
        [callout]="false"
        title="My Profile"
        class="refresh_icon"
        style="margin-right: 8px"
      />
      <img
        *ngIf="showRefreshIcon"
        kendoTooltip
        position="bottom"
        [callout]="false"
        title="Refresh"
        class="refresh_icon"
        style="margin-right: 8px"
        src="https://engageimages.z1.web.core.windows.net/Images/Verde/Other/reload.svg"
        alt=""
        (click)="refreshMyWorkspace()"
      />
      <verde-dots-menu-button style="margin-top: auto" [items]="dotMenuItems" (menuClicked)="menuClicked()" (menuItemClicked)="menuItemClicked($event)">
      </verde-dots-menu-button>
    </span>
  </div>
</div>

<verde-breadcrumb></verde-breadcrumb>
<div class="content"><router-outlet></router-outlet></div>

<kendo-floatingactionbutton
  *ngIf="showFAB"
  id="floatingbutton"
  icon="arrow-chevron-up"
  shape="circle"
  [align]="{ horizontal: 'end', vertical: 'bottom' }"
  positionMode="fixed"
  (click)="scrollMain(top)"
>
</kendo-floatingactionbutton>

<!-- Loading Modal -->
<verde-modal
  class="app-loading-modal"
  id="appLoadingModal"
  [hasCancelButton]="false"
  modalMinWidth="auto"
  modalMaxWidth="1100"
  [showHeader]="false"
  [showFooter]="false"
  [modalHasPadding]="false"
  overflowValue="hidden"
>
  <div class="loading-modal-content" *ngIf="!hasContinued">
    <kendo-skeleton
      *ngIf="
        ((deviceTypeService.isMobile$ | async) === false && loadingImageURL === null) ||
        ((deviceTypeService.isMobile$ | async) && loadingImageURLMobile === null)
      "
      shape="rectangle"
      animation="pulse"
    >
    </kendo-skeleton>
    <img [src]="loadingImageURL | safe" alt="" />
  </div>
  <div *ngIf="hasContinued" class="loading-modal-content loading-modal-grid">
    <div *ngIf="(deviceTypeService.isMobile$ | async) === false" class="social_media_rss_container">
      <iframe
        *ngIf="enableSplashScreen"
        [hidden]="showSocialMediaLoader"
        scrolling="no"
        src="https://rss.app/embed/v1/imageboard/kjdWeGHejg39YQve"
        frameborder="0"
      ></iframe>
      <kendo-skeleton *ngIf="showSocialMediaLoader" shape="rectangle" animation="pulse"> </kendo-skeleton>
    </div>
    <div class="loading-rss-grid">
      <iframe
        *ngIf="enableSplashScreen"
        [hidden]="showSocialMediaLoader"
        style="width: 100%; height: 100%; padding: 3px"
        scrolling="no"
        [src]="splashScreenURL | safe"
        frameborder="0"
      ></iframe>
      <kendo-skeleton *ngIf="showSocialMediaLoader" shape="rectangle" animation="pulse"> </kendo-skeleton>
      <iframe
        *ngIf="enableSplashScreen"
        [hidden]="showSocialMediaLoader"
        style="width: 100%; height: 100%; padding: 0.5px"
        scrolling="no"
        src="https://rss.app/embed/v1/wall/IAehLQm3lIwR0cSr"
        frameborder="0"
      ></iframe>
      <kendo-skeleton *ngIf="showSocialMediaLoader" shape="rectangle" animation="pulse"> </kendo-skeleton>
    </div>
  </div>
  <div class="loading-modal-footer">
    <div class="loading-left-section">
      <img src="https://verdeimages.z1.web.core.windows.net/Images/Verde/Splash/splashverde.png" alt="" />
    </div>
    <h5 class="notification" *ngIf="!contextIsBrowser() && !config?.verdeEnvironmentConfig?.teamsAppOnline">
      Please use {{ environment.redirectUri }} if you are not able to use the Verde here
    </h5>
    <div class="loading-right-section">
      <div style="position: relative; float: right">
        <ngx-spinner
          [disableAnimation]="disableAnimation"
          name="apploadingbutton"
          bdColor="rgb(255, 255, 255)"
          size="default"
          color="#6264a7"
          type="timer"
          style="position: relative; margin-bottom: 1rem; margin-right: 2rem; float: right"
          [fullScreen]="false"
        >
        </ngx-spinner>
        <button
          *ngIf="showNextButton"
          kendoButton
          themeColor="primary"
          style="left: 32px"
          [hidden]="!allowContinue"
          [disabled]="!allowContinue"
          (click)="continueInitialModal()"
        >
          {{ modalButtonText }}
        </button>
        <button *ngIf="!showNextButton" kendoButton themeColor="primary" style="left: 32px" [hidden]="showSocialMediaLoader" (click)="closeInitialModal()">
          Continue
        </button>
      </div>
    </div>
  </div>
</verde-modal>
