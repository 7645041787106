import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import {
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalGuardConfiguration,
  MsalInterceptor,
  MsalInterceptorConfiguration,
  MsalModule,
  MsalService,
  ProtectedResourceScopes,
} from '@azure/msal-angular';
import { BrowserCacheLocation, InteractionType, IPublicClientApplication, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import { VerdeSharedModule } from '@verde/shared';
import { ModuleImportGuard } from '../../module-import-guard';
import { LoginComponent } from './components/login/login.component';

export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

export function MSALInstanceFactory(environment: any): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.msalConfig.auth.clientId,
      authority: environment.msalConfig.auth.authority,
      redirectUri: environment.msalConfig.auth.redirectUri,
      postLogoutRedirectUri: environment.msalConfig.auth.redirectUri,
      clientCapabilities: ['CP1'], // This lets the resource server know that this client can handle claim challenges.
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Trace,
        piiLoggingEnabled: true,
      },
    },
  });
}

export function MSALInterceptorConfigFactory(environment: any): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string | ProtectedResourceScopes> | null>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', environment.graphConfig.scopes);
  Object.values(environment.features).forEach((feature) => {
    let url = feature as string;
    if (!environment.envConfig.includes('local')) {
      url = environment.urlOrigin + '/' + feature;
    }
    protectedResourceMap.set(url, [`${environment.msalConfig.auth.resourceUri}/${environment.msalConfig.auth.clientId}/verde-api`]);
  });
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(environment: any): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: [...environment.graphConfig.scopes, `${environment.msalConfig.auth.resourceUri}/${environment.msalConfig.auth.clientId}/verde-api`],
    },
    loginFailedRoute: '/login',
  };
}

@NgModule({
  imports: [MsalModule, CommonModule, VerdeSharedModule],
  declarations: [LoginComponent],
  exports: [MsalModule],
})
export class AuthenticationModule {
  constructor(@Optional() @SkipSelf() parentModule: AuthenticationModule) {
    ModuleImportGuard(parentModule, 'CoreModule');
  }

  static forRoot(ENV_TOKEN: any) {
    return {
      ngModule: AuthenticationModule,
      providers: [
        {
          provide: HTTP_INTERCEPTORS,
          useClass: MsalInterceptor,
          multi: true,
        },
        {
          provide: MSAL_INSTANCE,
          useFactory: MSALInstanceFactory,
          deps: [ENV_TOKEN],
        },
        {
          provide: MSAL_GUARD_CONFIG,
          useFactory: MSALGuardConfigFactory,
          deps: [ENV_TOKEN],
        },
        {
          provide: MSAL_INTERCEPTOR_CONFIG,
          useFactory: MSALInterceptorConfigFactory,
          deps: [ENV_TOKEN],
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
      ],
    };
  }
}
