import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { UserApiUserService, UserAppConfigDto, UserVerdeErrorType } from '@verde/api';
import { Subject, combineLatest, debounceTime, distinctUntilChanged, takeUntil } from 'rxjs';
import { AuthenticationService } from '../../services/authentication.service';
import { UserService } from '../../services/user.service';
import { ModalService } from '@verde/shared';
import { FormGroup } from '@angular/forms';
import { ThemeService } from '@verde/theme';

@Component({
  selector: 'verde-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<boolean>();
  config: UserAppConfigDto | undefined;
  heading?: string | null = "Welcome to Verde! We're glad you're here.";
  detail?: string | null = 'Make every workday a good one.';
  loginDisable = false;
  loginText = 'Login';
  noIDMatchError = false;

  constructor(
    private router: Router,
    private userService: UserService,
    private userApiUserService: UserApiUserService,
    private authenticationService: AuthenticationService,
    private modalService: ModalService,
    private themeService: ThemeService,
  ) {
    combineLatest([this.authenticationService.loaded$, this.userService.userLoaded$, this.userService.configLoaded$, this.userService.config$])
      .pipe(debounceTime(500), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((data) => {
        const authLoaded = data[0];
        const userLoaded = data[1];
        const configLoaded = data[2];
        this.config = data[3];

        if (authLoaded) {
          if (!userLoaded && !configLoaded && this.config === undefined) {
            this.heading = 'Signed In to Verde!';
            this.detail = 'Loading User Configuration ...';
            this.loginText = 'Signing In ...';
            this.loginDisable = true;
          } else if (userLoaded && this.config !== undefined) {
            this.heading = 'Signed In to Verde!';
            this.detail = 'Loading User Configuration ...';
            this.loginText = 'Signing In ...';
            this.loginDisable = true;
            if (this.config?.user?.azureObjectId === undefined && this.config?.user?.tenantId === undefined) {
              this.modalService.open('validateUserIDModal');
            } else {
              this.router.navigate(['/']);
            }
          } else {
            this.heading = this.config?.verdeEnvironmentConfig?.verdeErrorMessage;
            this.detail = this.config?.verdeEnvironmentConfig?.verdeErrorDetail;
            this.loginText = 'Retry Login';
            this.loginDisable = false;
          }
        }
      });
  }

  async ngOnInit() {
    this.themeService.apply();
  }

  async checkIDValid(myForm: FormGroup) {
    const employee = this.userService.proxyUser;
    const authState = this.authenticationService.authState$.getValue();

    if (employee !== undefined && authState !== undefined && myForm.value.validateIDNo === employee.idNumber) {
      const user = {
        ...this.userService.user,
        employeeId: employee.employeeId,
        azureObjectId: authState.azureObjectId,
        tenantId: authState.tenantId,
      };
      await this.userApiUserService
        .updateUser({
          body: {
            user,
          },
        })
        .toPromise();
      this.userService.proxyUser$.next({ ...user });
      this.userService.config$.next({
        ...(this.userService.config$.getValue() ?? {}),
        user,
      });
      await this.userService.getUserAppConfig(authState);
    } else {
      this.noIDMatchError = true;
      this.modalService.open('validateUserIDModal');
    }
  }

  async login() {
    this.loginDisable = true;
    this.loginText = 'Signing In ...';
    await this.authenticationService.signIn();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next(true);
    this.onDestroy$.complete();
  }
}
