/* tslint:disable */
/* eslint-disable */
export interface TimeOffTimeOffBalances {
  balance?: null | number;
  capping?: null | boolean;
  cycleEnd?: null | string;
  cycleStart?: null | string;
  cycleType?: null | string;
  due?: null | number;
  empNo?: null | string;
  fullName?: null | string;
  future?: null | number;
  id?: null | string;
  legalEntity?: null | string;
  max?: null | number;
  pending?: null | number;
  t1Manager?: null | string;
  t2Manager?: null | string;
  t3Manager?: null | string;
  toBeCapped?: null | number;
}
