<!--Loader doesn't seem to work here-->
<!-- <verde-loader [loading]="pageIsLoading"></verde-loader> -->
<div kendogridloading *ngIf="pageIsLoading" class="k-loading-mask ng-star-inserted">
  <span class="k-loading-text ng-star-inserted">Loading</span>
  <div class="k-loading-image ng-star-inserted"></div>
  <div class="k-loading-color ng-star-inserted"></div>
</div>

<div *ngIf="!pageIsLoading">
  <kendo-timeline *ngIf="events && events.length > 0" [events]="events" [collapsibleEvents]="true" [alterMode]="true" [attr.aria-hidden]="!pageIsLoading">
  </kendo-timeline>
  <p *ngIf="events.length === 0">No timeline events to display.</p>
</div>
