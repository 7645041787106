/* eslint-disable no-console */
/***************************************************************************************************
 * Load `$localize` onto the global scope - used if i18n tags appear in Angular templates.
 */
import '@angular/localize/init';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from './app/app.module';
import { environment } from '@environments-verde/environment';
// import * as Sentry from '@sentry/angular-ivy';
import 'hammerjs';
// import { CaptureConsole } from '@sentry/integrations';

// Sentry.init({
//   dsn: 'https://886b57ec0ea38bb94137993a6156a43d@o4505804564594688.ingest.sentry.io/4506161063854080',
//   integrations: [
//     new Sentry.BrowserTracing({
//       // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//       tracePropagationTargets: ['localhost', 'https://uatapp.verde.work', 'https://devapp.verde.work', 'https://app.verde.work'],
//       routingInstrumentation: Sentry.routingInstrumentation,
//     }),
//     new CaptureConsole({
//       levels: ['error'],
//     }),
//     // new Sentry.Replay(),
//   ],
//   environment: environment.envConfig,
//   attachStacktrace: true,
//   // Performance Monitoring
//   tracesSampleRate: 1.0, // Capture 100% of the transactions
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .then(() => console.log('Bootstrap success'))
  .catch((err) => console.error(err));
